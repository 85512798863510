
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

span.chip {
    background: #6c757d;
}


/*@media only screen and (min-width: 375px) and (max-width: 969px) {
    body {
        background: green;
        font-size: 16px;
    }
}*/