.specialOfferEdit {
    
}

.specialOfferEditRtl {
    text-align: right;
    direction: rtl;
}

.specialOfferEditContainer {
    /*margin: 10px 10px 50px 10px;*/
    margin: 0px;
    margin-top: -50px;
    /*padding-left: 0px !important;
    padding-right: 0px !important;*/
    padding: 0px;

    @media (max-width: 550px) {
        margin-top: 0px;
    }
}

.specialOfferEditTitleText {
    font-size: 1.4em;
    font-weight: 600;
    padding: 10px 5px;
    height: 50px;
}

.specialOfferEditTitleTextRtl {
    text-align: right;
    direction: rtl;
}

.topCloseButton {
    float: right;
}

.topCloseButtonRtl {
    float: left;
}

.specialOfferEditTitle {
    background-color: #352D57;
    height: 45px;
    color: #ffffff;
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1em;
    padding: 12px 5px;
    margin: 0px;
}

.specialOfferEditExplanationRow {
    min-height: 60px;
    padding-bottom: 10px;
    font-size: 1.3em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    border-bottom: 1px solid #000000;
}

.specialOfferEditRow {
    min-height: 60px;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    /* margin-bottom: 15px; */
    border-bottom: 1px solid #000000;
    padding-bottom: 10px;
}

.lastSpecialOfferEditRow {
    min-height: 60px;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    /* margin-bottom: 15px; */
    border-bottom: 0px solid #000000;
    text-align: center;
}

.specialOfferEditControlsCol {
    text-align: right;
    direction: ltr;
}

.specialOfferEditControlsColRtl {
    text-align: left;
    direction: ltr;
}

.specialOfferEditDropDown {
    width: 80px;
}

.specialOfferTagsDropDown {
    width: 100%;
    height: unset;
    min-height: 35px;
    overflow: visible;
    white-space: break-spaces;
    text-align: right;
}

.specialOfferEditNumeric {
    /*width: 88px;*/
}

.specialOfferEditInputField {
    border-radius: 5px;
}

.specialOfferEditInputField {
    direction: rtl;
    text-align: right;
}

.specialOfferEditPriceInputField {
    border-radius: 5px;

    span > input {
        border-radius: 5px !important;
    }
}

.specialOfferEditPriceInputField > span > b {
    display: none;
}

.specialOfferEditCategories {
}

.specialOfferEditFormPriceField {
    display: inline-block;
    padding: 5px 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    height: 30px;
    margin-top: 2px;
    font-weight: 600;
}

.specialOfferEditFormStockQuantityField {
    display: inline-block;
    width: 50px;
    padding: 3px 5px;
    height: 33px;
    margin: 5px 0px;
}

.hiddenElement {
    display: none;
}

.specialOfferEditBtn {
    background-color: #EB008B;
    width: 180px;
    height: 39px;
    border-radius: 18px;
    padding-top: 2px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px;
    text-decoration: none;
}

.specialOfferEditBtn:hover, .specialOfferEditBtn:active, .specialOfferEditBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.specialOfferEditBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}

.specialOfferEditInputValidationMessage {
    margin: 5px;
    color: #ff0000;

    @media (max-width: 550px) {
        font-size: 0.7em;
    }
}

.specialOfferEditInputValidationMessageRtl {
    direction: rtl;
}
