.orderRtl {
    direction: rtl;
    text-align: right;
}

.alignRight {
    text-align: right;
}

.alignLeft {
    text-align: left;
}

.deliveryZoneCard {
    /*margin: 5px 5px auto;
    line-height: 1.6em;
    width: calc(50% - 10px);*/
    margin: 5px 0 auto;
    line-height: 1.6em;
    padding: 0px;

    @media (max-width: 769px) {
        margin: 5px 0 auto;
        line-height: 1.6em;
        /*width: unset;*/
    }
}

.deliveryZoneCardRTL {
    text-align: right;
    direction: rtl;
}
    
.cardImg {
    max-height: 120px;
    width: fit-content;
    margin: 0 auto;
}

.cardHeader {
    padding: 0px;
    background-color: #ffffff;
    /*cursor: pointer;*/
    border-bottom: solid 0px #000000;
}

.cardRow {
    margin: 0px;
}

.cardEditSectionWrapper {
    padding: 0px 0px;
    line-height: 1.6em;
    font-weight: 600;
    font-size: 0.9em;
}

.cardEditRow {
    padding: 5px;
    margin: 0px;
    /*height: 50px;*/
}

.cardColumn {
    padding: 6px 14px;
}

.cardInfoContainer {
    flex-grow: 1;
    max-width: unset;
}

.cardTitle {
    /*display: flex;
    flex-direction: row;*/
    margin: 0px;
    text-align: left;
    padding: 5px;
    font-weight: 600;
}

.cardTitleRtl {
    text-align: right;
}

/*.cardTitleName {
    font-size: 1.4em;
    flex: 1 0 55%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 43px;
    font-weight: 600;
    padding: 0px 5px;
}*/

.cardNavLink {
    display: inline-block;
    color: #000000;
    padding: 0px;
    font-size: 1.1em;
    text-decoration: none;
    padding-top: 3px;
}

.cardNavLink, .cardNavLink:visited, .cardNavLink:active, .cardNavLink:hover {
    color: #000000;
}

.orderRtl .cardTitle {
    /*flex-direction: row-reverse;*/
}

.cardTitleIcon {
    font-size: 1.4em;
    /*flex: 1 0 15%;*/
    text-align: right;
    padding-top: 7px;
}

.cardTitleIconRTL {
    text-align: left;
}