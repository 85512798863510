.searchWrpperFixed {
    position: fixed;
    top: 58px;
    right: 0;
    left: 0;
    z-index: 6;
}

.searchFormRtl {
    direction: rtl;
    text-align: right;
    /*flex-direction: row-reverse;*/
}

.btnGroup {
    position: relative;
    width: calc(100% - 2px);
}

.btn {
    border-radius: 0;
    width: 33.333%;
    color: #000000;
    background-color: #F6F6F6;
    padding: 5px;
}

.btn:hover, .btn:active, .btn:focus, .btn::selection {
    color: #000000;
    background-color: #F6F6F6 !important;
}

.btnActive {
    color: #ffffff;
    background-color: #4A33A4;
    padding: 5px;
}

.btnActive:hover, .btnActive:active, .btnActive:focus, .btnActive::selection {
    color: #ffffff;
    background-color: #4A33A4 !important;
}


