.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 100;
}

.popup {
    position: fixed;
    /* top: 0; */
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0px auto;
    padding: 20px;
    background: #fff;
    border-radius: 50px 50px 0px 0px;
    transition: all 5s ease-in-out;
    width: 100%;
    z-index: 101;
}

.popup h2 {
    margin-top: 0;
    color: #000000;
    margin: 15px;
    text-align: center;
    font-weight: 600;
}

.popup .close {
    color: #ffffff;
    position: absolute;
    top: -50px;
    right: 10px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
}

.popup .close:hover {
    cursor: pointer;
    color: #000;
}

.popup .content {
    max-height: 70vh;
    overflow: auto;
}

@media screen and (max-width: 700px) {
    .popup {
        width: 100%;
    }
}
