.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    transition: opacity 500ms;
    z-index: 10000;
}

.loader {
    position: fixed;
    top: 40%;
    /* top: 0; */
    /*bottom: 0;*/
    left: 0;
    right: 0;
    margin: 0px auto;
    padding: 20px;
    text-align: center;
    
    background-color: transparent;
    /*background: #fff;
    border-radius: 50px 50px 0px 0px;*/
    
    transition: all 5s ease-in-out;
    width: 100%;
    z-index: 1001;
    min-height: 250px;
}

.loader img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 0px solid black;
    margin: 0px auto;
    -webkit-animation: rotateY 1s infinite linear;
    animation: rotateY 2s infinite linear;
}

@-webkit-keyframes rotateY {
    to {
        -webkit-transform: rotateY(360deg);
    }
}

@keyframes rotateY {
    to {
        transform: rotateY(360deg);
    }
}

@media screen and (max-width: 700px) {
    .loader {
        width: 100%;
    }
}
