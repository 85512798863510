.timePickerWrapper {
    position: relative;
    direction: ltr;
    text-align: left;
    min-width: 80px;
}

.timePickerWrapperRtl {
    text-align: right;
}

.timePickerHourBox {
    display: inline-block;
    width: 40%;
    margin: 0px;
    border-right: solid 0px #ffffff;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    text-align: center;
}

.timePickerMinuteBox {
    display: inline-block;
    width: 40%;
    margin: 0px;
    border-left: solid 0px #ffffff;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    text-align: center;
}

.timePickerColumnBox {
    border: solid 1px #ced4da;
    border-right: solid 0px #ffffff;
    border-left: solid 0px #ffffff;
    text-align: center;
    line-height: 2em;
}
