body {
}

.preferencesRtl {
    text-align: right;
    direction: rtl;
}

.preferencesRtlOnlyAlign {
    text-align: right;
}

.preferencesRtlOnlyAlign::after {
    float: left;
    margin-top: 11px;
}

.preferencesRow {
    height: 60px;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    /* margin-bottom: 15px; */
    border-bottom: 1px solid #000000;
}

.perferencesControlsCol {
    text-align: right;
    direction: ltr;
}

.perferencesControlsColRtl {
    text-align: left;
    direction: ltr;
}

.btnGroup {
    direction: ltr;
    text-align: left;
}

.dropdownWindow {
    max-height: 200px;
    overflow-y: auto;
    max-width: 250px;
}

/*****************************************************/

.shopsAlertMain div {
    text-align: center;
    font-size: 1.1em;
}

.shopsAlertBellWrapper {
    text-align: center;
}

.shopsAlertActionsWrapper {
    text-align: center;
}

.shopsAlertActionBtn {
    background-color: #EB008B;
    width: 180px;
    height: 39px;
    border-radius: 18px;
    padding-top: 2px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px auto;
    display: block;
    outline: none;
    border-width: 0px;
    text-decoration: none;
}

.shopsAlertActionBtn:hover, .shopsAlertActionBtn:active, .shopsAlertActionBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.shopsAlertActionBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}

/*****************************************************/

.modalHeaderRTL {
    direction: rtl;
    text-align: right;
    /*flex-direction: row-reverse;*/
}

.modalHeaderRTL h5 {
    /*float: left;*/
    width: 100%;
}

.modalBody {
    /*text-align: center;*/
    padding: 0px;
}
.modalBodyRtl {
    text-align: right;
    direction: rtl;
}

.editRow {
    min-height: 60px;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    /* margin-bottom: 15px; */
    border-bottom: 1px solid #000000;
    padding-bottom: 10px;
}

.lastEditRow {
    height: 60px;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    /* margin-bottom: 15px; */
    border-bottom: 0px solid #000000;
    text-align: center;
}

.editControlsCol {
    text-align: right;
    direction: ltr;
}

.editControlsColRtl {
    text-align: left;
    direction: ltr;
}

.editDatesCol {
    text-align: left;
    direction: ltr;
}

.editDatesCol > div {
    width: 110px
}

.editDatesCol > span {
    width: 20px;
    height: 40px;
    display: block;
    float: left;
    margin-top: 4px;
    margin-right: 5px;
}

.editTimeCol {
    text-align: right;
    direction: ltr;
}

.timeDropDown {
    width: 90px;
}

.editTimeCol > span {
    width: 40px;
    height: 40px;
    display: inline-block;
    /*float: right;*/
    margin-top: 4px;
    margin-right: 5px;
}

.editDropDown {
    min-width: 150px;
}

.dropdownWindow {
    max-height: 200px;
    overflow-y: auto;
    max-width: 250px;
}

.editBtn {
    background-color: #EB008B;
    width: 180px;
    height: 39px;
    border-radius: 18px;
    padding-top: 2px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px;
    text-decoration: none;
}

.editBtn:hover, .editBtn:active, .editBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.editBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}

.immediateValueInMenu {
    margin: 0px 10px;
}



.futureEditInputValidationMessage {
    color: #ff0000;
    font-size: 0.7em;
}

.futureEditInputValidationMessageRtl {
    direction: rtl;
}