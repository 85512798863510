
.navbar {
    z-index: 9;
    background-color: #ffffff;
    min-height: 58px;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navBarLinksWrap {
    flex-grow: 0;
}

.navSeparator {
    padding-top: 7px;
    display: none;
}

@media (min-width: 768px) {
    .navListRtl {
        flex-direction: row-reverse !important;
    }

    .navSeparator {
        display: inline-block;
    }
}

.navLink {
    font-size: 1.1em;
    font-weight: 600;
    cursor: pointer;

    @media (max-width: 769px) {
        border-bottom: 1px solid #000000;
        height: 2.5em;
    }
}

.navLinkRtl {
    text-align: right;
    direction: rtl;
}

.navList {
    text-align: left;
    direction: ltr;

    li:last-child > .navLink {
        font-weight: 400;

        @media (max-width: 769px) {
            font-weight: 600;
        }
    }
}

.navListRtl {
    text-align: right;
    /*direction: rtl;*/
    padding-right: 0px;

    li:last-child > .navLink {
        font-weight: 400;

        @media (max-width: 769px) {
            font-weight: 600;
        }
    }
}


.flexContainer {
    display: flex;
}

.navSearchContainer {
    /*width: 80px;*/
    position: initial;
    align-items: center;
    display: flex !important;
    flex-basis: auto;
    flex-grow: 1;
    /*flex-flow: row wrap;*/
    flex-flow: row-reverse;
    margin-right: 8px;
}

.searchFrame {
    /*position: absolute;*/
    height: 40px;
    overflow: hidden;
    max-width: 100%;
    z-index: 10;
    text-align: right;
    direction: rtl;
    /*width: 100%;*/
    width: 30px;
    transition: ease-in-out width 0.5s;
    position: relative;

    @media (max-width: 769px) {
    }

    .navSearchBtn {
        min-width: 30px;
        overflow: hidden;
        transition: ease-in-out width 0.5s;
        z-index: 11;
    }

    .navSearchCloseBtn {
        width: 0px;
        overflow: hidden;
        transition: ease-in-out width 0.5s;
        z-index: 10;

        * {
            display: none;
        }
    }
}

.searchFrameOpen {
    width: 100%;

    .navSearchBtn {
        min-width: 30px;
    }

    .navSearchCloseBtn {
        width: 30px;

        * {
            display: initial;
        }
    }
}

.navMenuMagnifier {
    /*font-size: 1.5em;*/
    margin-top: 11px;
    margin-right: 9px;
    z-index: 12;
    position: absolute;

    @media (max-width: 769px) {
        margin-top: 10px;
        margin-right: 9px;
    }
}

.navSearchBtn {
    background-color: #ffffff;
    color: #000000;
    border-left: solid 0px #000000;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    margin: 0px !important;
    padding: 0px;
    top: 0;
    right: 0;
}

.navSearchBtn svg {
    margin-top: -3px;
    margin-right: 3px;
}

.navSearchBtn:hover {
    background-color: #e3e1ee;
    color: #000000;
}

.navSearchBtn:focus, .navSearchBtn:active, .navSearchBtn::selection {
    background-color: #ffffff;
    color: #000000;
}

.inputGroup {
    width: 100% !important;
    overflow: hidden;
    word-break: keep-all;
    flex-wrap: nowrap;
    position: relative;
}

.navSearchInput {
    border-radius: 0px;
    border-top: solid 1px #000000;
    border-right: solid 0px #000000;
    border-bottom: solid 1px #000000;
    border-left: solid 0px #000000;
    margin: 0px !important;
}

.navSearchCloseBtn {
    background-color: #ffffff;
    color: #000000;
    border-right: solid 0px #000000;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    margin: 0px !important;
    padding: 0px;
}

.navSearchCloseBtn:hover {
    background-color: #e3e1ee;
    color: #000000;
}

.navSearchCloseBtn:focus, .navSearchCloseBtn:active, .navSearchCloseBtn::selection {
    background-color: #ffffff;
    color: #000000;
}

.navSearchCloseBtn svg {
    margin-top: -3px;
}

.navSearchFieldClosed {
    width: 0px;
    display: none !important;
}

.navSearchFieldOpen {
    /*width: 100px;*/
    display: inline-block;
}