.productImageListRtl {
    text-align: right;
    direction: rtl;
}

.productImageListContainer {
    margin-top: 20px;

    @media (max-width: 550px) {
        padding: 0px;
        margin: 10px auto;
        margin-top: 100px;
    }    
}

.thumbsContainer {
    /*width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;*/

    width: 150px;
    height: 125px;
    border-radius: 10px;
    object-fit: contain;
    object-position: center;
    /*border: dashed 1px #000000;*/
    border: 1px dashed rgba(0, 0, 0, 0.2);
    margin: 0px auto;
    display: block;
}
