

.modalProductContainer {
    /*margin: 10px 10px 50px 10px;*/
    margin: 0px;;
    /*padding-left: 0px !important;
    padding-right: 0px !important;*/
    padding: 5px 10px 100px 10px;
}

.modalProductRow {
    margin: 0px;
    padding: 0px;
}

.modalSearchContainer {
    width: calc(100% - 60px);
    padding: 0px;
    border-radius: 15px;
    border: solid 1px #000000;
    height: 30px;
    margin: 10px auto;
}

.modalSearchInput {
    /*border: solid 0px #000000;*/
    border: none;
    display: block;
    width: calc(100% - 50px);
    padding: 0px 0px;
    height: 28px;
    font-size: 1.1em;
    font-weight: 600;
    margin: 0px 10px;
    outline: none;
    float: left;
}

.modalSearchInputRtl {
    float: right;
}

.modalSearchInput:active, .modalSearchInput:focus, .modalSearchInput:current {
    border: none;
    outline: none;
}

.modalSearchIcon {
    display: block;
    content: ' ';
    background-image: url('/assets/search_icon.svg');
    background-repeat: no-repeat;
    background-size: 21px 21px;
    background-position: center;
    height: 30px;
    width: 30px;
    margin: 0px;
    float: left;
}

.modalSearchIconRtl {
    float: right;
}

.modalSearchResultsTitle {
    background-color: #352D57;
    height: 45px;
    color: #ffffff;
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1em;
    padding: 12px 5px;
}

.infiniteScrollQueue {
    margin: auto;
    text-align: center;
}