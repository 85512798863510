.container {

}

.loginContainer {
    position: absolute;
    width: 100%;
}

.loginRtl {
    text-align: right;
    direction: rtl;
}

h1 {
    color: #ffffff;
    font-size: 1.5em;
    text-align: center;
}

.loginFormContainer {
    display: block;
    font-size: 1.1em;
    width: 300px;
    color: #ffffff;
    z-index: 6;
    /*min-height: 100vh;*/
    /*@media (max-width: 768px) {
        margin-top: 50px;
        width: 100%;
        padding: 15px;
    }*/
    align-content: center;
    margin: 0px auto;
    margin-top: 65px;
    text-align: center;

    input {
        font-size: 1.2em;
        width: 100%;
        padding: 15px 15px;
        height: 65px;
        border-radius: 32px;
        background-color: #ffffff;
    }
}

.error {
  color: #ffffff;
  text-align: center;
  height: 30px;
  font-size: 0.9em;
  line-height: 1em;
}

.forgotPassword {
    color: #ffffff;
    text-align: center;
    height: 30px;

    a {
        color: #ffffff;
        font-size: 0.9em;
        line-height: 1em;
        text-decoration: underline;
    }
}

.hidden {
    display: none;
}
 
.loginForm {
  display: flex;
  flex-direction: column;
}
 
.loginFormItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.loginFormBtn {
    font-size: 1.2em;
    right: auto;
    left: 0;
    height: 65px;
    width: 100%;
    padding: 10px 10px;
    border-radius: 32px;
    background-color: #ec02a1;
    border-color: #fd24b7;
    /*margin-top: 35px;*/
    text-align: center;
}

.linkBtn {
    padding-top: 17px;
}

.loginFormBtn:hover {
    color: #fff;
    background-color: #b9027e;
    border-color: #e2029a;
}

.loginFormBtn:active {
    color: #fff;
    background-color: #b9027e;
    border-color: #e2029a;
}

.loginFormBtn:disabled {
    color: #fff;
    background-color: #b9027e;
    border-color: #e2029a;
}

.loginFormBtn:visited {
    color: #fff;
    background-color: #ec02a1;
    border-color: #fd24b7;
}

.loginFormBtn::selection {
    color: #fff;
    background-color: #b9027e;
    border-color: #e2029a;
}

.gradientBackground {
    /*min-height: calc(100vh - 73px);*/
    /*min-height: 100vh;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*position: relative;*/
    /*min-height: 611px;*/
    /*max-height: 700px;*/
    /*left: 0px;*/
    /*top: 148px;*/
    background: linear-gradient(180deg, #4B34A8 0%, #27194A 100%);
    width: 100%;
    display: block;
    position: absolute;
    overflow: hidden;
    padding: 58px 0 0 0;
    /*margin: -58px 0 0 0;*/
    top: 58px;
    right: 0;
    bottom: 0;
    left: 0;

    @media (max-width: 1024px) {
        /*min-height: 830px;*/
        /*height: 300px;*/
    }

    @media (max-width: 768px) {
        /*min-height: 483px;
        height: 200px;*/
    }

    @media (max-width: 400px) {
        /*min-height: 580px;*/
        /*height: 300px;*/
    }
}

.homepageEmphasizedText
{
    margin-top: 77px;
    font-size: 3.9em;
    line-height: 1em;
    color: #ffffff;
    text-align: center;
    font-weight: 600;

    @media (max-width: 768px) {
        font-size: 2.5em;
        margin-top: 45px;
        margin-left: 30px;
        margin-right: 30px;
    }
}

.homepageTopSmallerText {
    margin-top: 25px;
    font-size: 2em;
    color: #ffffff;
    text-align: center;
    font-weight: 600;

    @media (max-width: 768px) {
        /*font-size: 1.5em;
                margin-top: 10px;*/
        display: none;
    }
}

.tomatos {
    position: absolute;
    left: 10%;
    /*right: 83.01%;*/
    /*top: 304px;*/
    /*bottom: 81.14%;*/
    /*opacity: 0.2;*/
    color: #6b59aF;

    @media (max-width: 768px) {
        top: 240px;
        left: 2%;
    }
}

.carrots {
    position: absolute;
    left: 13.56%;
    /*right: 76.69%;*/
    top: 0px;
    /*bottom: 400px;*/
    /*opacity: 0.2;*/
    color: #6b59aF;

    @media (max-width: 768px) {
    }
}

.eggplants {
    position: absolute;
    left: 83.12%;
    /*right: 7.64%;*/
    top: 50px;
    /*bottom: 89.4%;*/
    /*opacity: 0.2;*/
    color: #6b59aF;

    @media (max-width: 768px) {
        display: none;
    }
}

.mushroom {
    position: absolute;
    width: 90.25px;
    height: 85.48px;
    left: 75%;
    top: 229px;
    /*opacity: 0.2;*/
    color: #6b59aF;

    @media (max-width: 768px) {
        top: unset;
        left: unset;
        right: 35px;
        bottom: 200px;
    }
}

.lettuce {
    position: absolute;
    width: 85.14px;
    height: 142.98px;
    left: 30%;
    top: 216px;
    /*opacity: 0.2;*/
    color: #6b59aF;
    transform: rotate(-13.85deg);

    @media (max-width: 768px) {
        display: none;
    }
}

.butternut {
    position: absolute;
    width: 85.98px;
    height: 194.54px;
    left: -15px;
    top: 210px;
    /*opacity: 0.2;*/
    color: #6b59aF;

    @media (max-width: 768px) {
        top: 31px;
    }
}

.bellpepper {
    position: absolute;
    width: 124.79px;
    height: 58.12px;
    left: 1%;
    top: 0px;
    /*opacity: 0.2;*/
    color: #6b59aF;

    @media (max-width: 768px) {
        top: -10px;
        left: -5px;
    }
}

.artichoke {
    position: absolute;
    width: 131.15px;
    height: 146.05px;
    left: 90%;
    top: 203px;
    /*opacity: 0.2;*/
    color: #6b59aF;
    transform: rotate(-6.57deg);

    @media (max-width: 768px) {
        display: none;
    }
}

.grapes {
    position: absolute;
    width: 197.57px;
    height: 142.58px;
    left: 10%;
    /*top: 590px;*/
    bottom: 100px;
    /*opacity: 0.2;*/
    color: #6b59aF;
    transform: matrix(-0.8, 0.6, 0.6, 0.8, 0, 0);

    @media (max-width: 768px) {
        left: unset;
        bottom: 40px;
        right: 10px;
    }
}

.cherries {
    position: absolute;
    width: 79.09px;
    height: 105.05px;
    left: 107px;
    top: 107px;
    /*opacity: 0.2;*/
    color: #6b59aF;

    @media (max-width: 768px) {
        display: none;
    }
}

.melon {
    position: absolute;
    width: 235.81px;
    height: 131.82px;
    left: 80%;
    top: 400px;
    /*opacity: 0.2;*/
    color: #6b59aF;

    @media (max-width: 768px) {
        left: 40px;
        top: unset;
        bottom: -30px;
    }
}

.corn {
    position: absolute;
    /*left: 94.38%;*/
    right: 0.02%;
    top: -30px;
    /*bottom: 89.54%;*/
    /*opacity: 0.2;*/
    color: #6b59aF;

    @media (max-width: 768px) {
        right: -10px;
        top: -20px;
    }
}
