.orderRtl {
    direction: rtl;
    text-align: right;
}

.alignRight {
    text-align: right;
}

.alignLeft {
    text-align: left;
}

.orderCard {
    margin: 10px 5px;
    color: #000000;
}

.cardImg {
    max-height: 120px;
    width: fit-content;
    margin: 0 auto;
}

.cardHeader {
    line-height: 1em;
    /*padding: 0px 5px;*/
    /*background-color: #D3D1DE;*/
    background-color: #E3E1EE;
    /*padding-top: 15px;*/
    padding: 5px;
}

.cardTitle {
    display: flex;
    flex-direction: row;
    margin: 0px;
}

.orderRtl .cardTitle {
    /*flex-direction: row-reverse;*/
}

.cardTitleName {
    font-size: 1.4em;
    flex: 1 0 55%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 43px;
    font-weight: 600;
    padding: 0px 5px;
}

.cardTitleId {
    /*font-size: 1.4em;
    flex: 1 0 20%;*/
    font-size: 0.7em;
    font-weight: 400;
    display: block;
    width: 100%;
    height: 20px;
}

.cardTitleHour {
    font-weight: 600;
    font-size: 1.4em;
    flex: 1 0 30%;
    height: 36px;
    font-weight: 600;
}

.cardTitleTimeCaption {
    /*font-size: 1.4em;
    flex: 1 0 20%;*/
    font-size: 0.7em;
    font-weight: 400;
    display: block;
    width: 100%;
    height: 20px;
}

.cardTitleIcon {
    font-size: 1.4em;
    flex: 1 0 15%;
    text-align: right;
    padding-top: 7px;
}

.cardTitleIconRTL {
    text-align: left;
}

/*.timeLeftBox {
    text-align: center;
}*/

.cardComment {
    color: #4A33A4;
    font-weight: 600;
}

.cardNavLink {
    color: #ffffff;
    padding: 0px;
    font-size: 1em;
}

.cardNavLink, .cardNavLink:visited, .cardNavLink:active, .cardNavLink:hover {
    color: #ffffff;
}

.cardNavLink:disabled {
    background-color: #231f20;
}

.cardRow {
    margin: 0px 0px;
    padding: 0px 10px;
}

/*.cardRow:first-child {
    margin-top: 10px;
}*/

.cardAdvancementRow {
    background-color: #F3F3F3;
    padding: 10px 10px;
}

.cardAdvancementCol {
    /*background-color: #F3F3F3;*/
}

.cardActionsRow {
    margin: 0px;
    padding: 10px;
    background-color: #E3E1EE;

    a {
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
    }
}

.cardBody {
    padding: 0px;
    margin: 0px;
    position: relative;
}

.cardCol {
    padding-left: 0px;
    padding-right: 0px;
}

.cardFirstRowSpacer {
    width: 100%;
    height: 10px;
}

.cardOrderCharged {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 60px;
    height: 50px;
    background-color: #4A33A4;
    font-size: 0.9em;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    padding-top: 7px;
}

.cardText {
    font-size: 1.2em;
    line-height: 1.5em;
}

.cardActionsCol {
    color: #ffffff;
    text-align: center;
}

.cardHeaderLink {
    text-decoration: none;
    color: #000000;
}

.cardActionLink {
    background-color: #4A33A4;
    width: 96px;
    height: 39px;
    border-radius: 18px;
    font-size: 1em;
    text-decoration: none;
}

a.cardActionLink {
    padding-top: 3px;

    @media (max-width: 769px) {
        padding-top: 6px;
    }
}

.cardActionLink:disabled {
    background-color: #231f20;
}

.cardCollectionLink {
    background-color: #EB008B;
    width: 96px;
    height: 39px;
    border-radius: 18px;
}

.cardCollectionLink:disabled {
    background-color: #231f20;
}

.cardEditingByUserText {
    color: red;
}

.cardCallToolTipBg {
    background-color: #E3E1EE;
}

.cardCallToolTipBg > div > div {
    background-color: #E3E1EE;
    color: #000;
    border: 1px solid #4A33A4;
}

/*.cardCallToolTipBg .tooltip-arrow {
    border-top: 5px solid #4A33A4;
}*/

.cardCallToolTipNavLink {
    color: #ffffff;
    padding: 0px;
}

.cardCallToolTipNavLink, .cardCallToolTipNavLink:visited, .cardCallToolTipNavLink:active, .cardCallToolTipNavLink:hover {
    color: #ffffff;
}

.cardCallToolTipActionLink {
    background-color: #4A33A4;
    width: 100%;
    height: 39px;
    border-radius: 5px;
    font-size: 1.2em;
    text-decoration: none;
    margin: 2px;
}

a.cardCallToolTipActionLink {
    padding-top: 3px;

    @media (max-width: 769px) {
        padding-top: 6px;
    }
}

.cardCallToolTipActionLink:disabled {
    background-color: #231f20;
}

.cardCallToolTipActionLink > img, .cardCallToolTipActionLink > svg {
    float: left;
    margin: 3px;
}
