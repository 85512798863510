.alignRight {
    text-align: right;
}

.alignLeft {
    text-align: left;
}

.specialOfferCard {
    /*margin: 5px 5px auto;
    line-height: 1.6em;
    width: calc(50% - 10px);*/
    margin: 5px 0 auto;
    line-height: 1.6em;
    padding: 0px;

    @media (max-width: 769px) {
        margin: 5px 0 auto;
        line-height: 1.6em;
        /*width: unset;*/
    }
}

.specialOfferCardRTL {
    text-align: right;
    direction: rtl;
}

.cardHeader {
    padding: 0px;
    background-color: #ffffff;
    cursor: pointer;
    border-bottom: solid 0px #000000;
}

.cardRow {
    margin: 0px;
}

.cardColumn {
    padding: 0px;
}

.cardInfoContainer {
    flex-grow: 1;
    max-width: unset;
}

.cardTitle {
    /*display: flex;
    flex-direction: row;*/
    margin: 0px;
    text-align: left;
    padding: 5px;
}

.cardTitleRtl {
    text-align: right;
}

.cardTitleIcon {
    font-size: 1.0em;
    /*flex: 1 0 15%;*/
    text-align: right;
    padding-top: 4px;
}

.cardTitleIconRTL {
    text-align: left;
}

.cardRemoveIcon {
    font-size: 1.0em;
    /*flex: 1 0 15%;*/
    text-align: left;
    padding-top: 4px;
    cursor: default;
}

.cardRemoveIconRTL {
    text-align: right;
}

.cardEditSectionWrapper {
    padding: 0px 0px;
    line-height: 1.6em;
    font-weight: 600;
    font-size: 0.9em;
}

.cardEditRow {
    padding: 5px;
    margin: 0px;
    /*height: 50px;*/
}

.cardNavLink {
    display: inline-block;
    color: #000000;
    padding: 0px;
    font-size: 1.1em;
    text-decoration: underline;
}

.cardNavLink, .cardNavLink:visited, .cardNavLink:active, .cardNavLink:hover {
    color: #000000;
}

.orderRtl .cardTitle {
    /*flex-direction: row-reverse;*/
}

.productEditBtn {
    background-color: #EB008B;
    width: 180px;
    height: 39px;
    border-radius: 18px;
    padding-top: 2px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px;
    text-decoration: none;
}

.productEditBtn:hover, .productEditBtn:active, .productEditBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.productEditBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}

.productEditNarrowBtn {
    width: 100px;
}

.productItemsContainer {
    padding: 0px;
}

.removeButton, .removeButton:visited {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    padding-top: 0px;
    color: #EB008B;
    font-size: 1.6em;
    margin: 0px;
    text-decoration: none;
    line-height: 0.2;
    margin-top: -10px;
}

.removeButton:hover, .removeButton:active {
    color: #EB008B;
    /*color: #ffffff;
    background-color: #EB008B;*/
}

.productTrashBtn, .productTrashBtn:hover, .productTrashBtn:active, .productTrashBtn:visited {
    color: #000000;
}
