.leavePageBlockerMain div {
    text-align: center;
    font-size: 1.1em;
}

.leavePageBlockerWrapper {
    text-align: center;
}

.leavePageBlockerMessageWrapper {
    padding-top: 25px;
}

.leavePageBlockerActionsWrapper {
    text-align: center;
}

.leavePageBlockerActionsWrapperRTL {
    direction: rtl;
}

.leavePageBlockerActionBtn {
    background-color: #EB008B;
    width: 180px;
    height: 39px;
    border-radius: 18px;
    padding-top: 2px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px auto;
    display: block;
    outline: none;
    border-width: 0px;
    text-decoration: none;
}

.leavePageBlockerActionBtn:hover, .leavePageBlockerActionBtn:active, .leavePageBlockerActionBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.leavePageBlockerActionBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}
