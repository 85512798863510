.productImageListItemRtl {
    text-align: right;
    direction: rtl;
}

.productImageListItemRow {
    min-height: 60px;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    /* margin-bottom: 15px; */
    border-bottom: 1px solid #000000;
    padding-bottom: 10px;
    align-items: center;
    justify-content: space-between;    

    * {
        width: auto;
    }
}

.productImageListItemRow {
    img, video {
        width: 200px;
        align-self: center;
    }
}

video {
    display: flex;
    height: 200px;
    width: 200px;
    margin-right: 15px;
}
