input {
    border: 1px solid #000000;
    /*padding: 5px;*/
    width: 100%;
    border-radius: 5px;
}

.noSuggestions {
    color: #000000;
    padding: 10px;
    padding-left: 0;
    width: 300px;
    border: 1px solid #000000;
    background-color: #ffffff;
    z-index: 1200;
    position: absolute;
    top: 27px;
    left: 0px;
}

.suggestions {
    border: 1px solid #000000;
    /*border-top-width: 0;*/
    list-style: none;
    margin-top: 0;
    max-height: 350px;
    overflow-y: scroll;
    padding-left: 0;
    width: 300px;
    background-color: #ffffff;
    z-index: 1200;
    position: absolute;
    top: 27px;
    left: 0px;
}

.suggestions li {
    padding: 10px;
}

.suggestionActive,
.suggestions li:hover {
    background-color: #EB008B;
    color: #ffffff;
    cursor: pointer;
    font-weight: 600;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #000000;
}
