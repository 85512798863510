body {
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.numericInputWithButtons {
    display: inline-block;
    margin: 0px 0px;
    min-width: 135px;
    position: relative;
    text-align: center;
}

.numericInputWithButtonsRtl {
    direction: rtl;
    /*text-align: right;*/
    /*flex-direction: row-reverse;*/

    .numericInputFieldButton:first-child {
        right: unset;
        left: 0px;
    }

    .numericInputFieldButton:last-child {
        left: unset;
        right: 0px;
    }
}

.numericInputField {
    /*display: inline-block;*/
    /*font-size: 1.1em;*/
    font-weight: 600;
    width: 70px;
    height: 30px;
    margin: 0px auto;
    padding: 4px 4px;
    text-align: center;
}

.numericInputField:active, .numericInputField:focus, .numericInputField:hover {
    
}

.numericInputFieldButton {
    /*display: none;*/
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: solid 1px #000000;
    background-color: #fff;
    color: #000;
    padding: 0px;
    padding-bottom: 3px;
}

.numericInputFieldButtonShown {
    display: block;
    position: absolute;
}

.numericInputFieldButton:first-child {
    top: 0px;
    width: 30px;
    height: 30px;
    right: 0px;
}

.numericInputFieldButton:last-child {
    top: 0px;
    width: 30px;
    height: 30px;
    left: 0px;
}

