body {
}

.preferencesRtl {
    text-align: right;
    direction: rtl;
}

.preferencesRow {
    height: 60px;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    /* margin-bottom: 15px; */
    border-bottom: 1px solid #000000;
}

.perferencesControlsCol {
    text-align: right;
    direction: ltr;
}

.perferencesControlsColRtl {
    text-align: left;
    direction: ltr;
}

.btnGroup {
    direction: ltr;
    text-align: left;
}

.dropdownWindow {
    max-height: 200px;
    overflow-y: auto;
    max-width: 250px;
}

.navLink {
    font-size: 1.1em;
    font-weight: 500;
    cursor: pointer;
    text-align: left;
    direction: ltr;
    margin: 0px;
    padding: 0px;
    text-decoration: underline;

    @media (max-width: 769px) {
        /*border-bottom: 1px solid #000000;*/
        /*height: 2.5em;*/
    }
}

.navLinkRtl {
    text-align: right;
    direction: rtl;
}