.orderRtl {
    direction: rtl;
    text-align: right;
}

.orderCard {
    margin-top: 15px;
    color: #000000;
}

.cardImg {
    max-height: 120px;
    width: fit-content;
    margin: 0 auto;
}

.cardTitle {
    line-height: 9px;
    font-weight: 600;
    font-size: 1.1em;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.cardSubTitle {
    font-weight: 600;
    font-size: 0.7em;
}

.timeLeftBox {
    text-align: center;
}

.cardNavLink {
    /*padding-left: 0px !important;
    padding-right: 0px !important;*/
    padding: 0px !important;
    margin: 0.5rem 1rem;
}

.cardRow {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.cardBody
{
    /*margin-left: 0px;
    margin-right: 0px;*/
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.cardCol {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.cardHeaderLink {
    text-decoration: none;
    color: #000000;
}
