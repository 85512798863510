.productEditRtl {
    text-align: right;
    direction: rtl;
}

.modalProductEditContainer {
    /*margin: 10px 10px 50px 10px;*/
    margin: 0px;
    ;
    /*padding-left: 0px !important;
    padding-right: 0px !important;*/
    padding: 5px 10px 100px 10px;
}

.modalProductEditTitle {
    background-color: #352D57;
    height: 45px;
    color: #ffffff;
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1em;
    padding: 12px 5px;
}

.productEditExplanationRow {
    min-height: 60px;
    padding-bottom: 10px;
    font-size: 1.3em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    border-bottom: 1px solid #000000;
}

.productEditRow {
    min-height: 60px;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    /* margin-bottom: 15px; */
    border-bottom: 1px solid #000000;
    padding-bottom: 10px;
}

.lastProductEditRow {
    height: 60px;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    /* margin-bottom: 15px; */
    border-bottom: 0px solid #000000;
    text-align: center;
}

.productEditControlsCol {
    text-align: right;
    direction: ltr;
}

.productEditControlsColRtl {
    text-align: left;
    direction: ltr;
}

.productEditDropDown {
    width: 80px;
}

.productTagsDropDown {
    width: 100%;
    height: unset;
    min-height: 35px;
    overflow: visible;
    white-space: break-spaces;
    text-align: right;
}

.productEditNumeric {
    /*width: 88px;*/
}

.productEditPriceInputField {
    border-radius: 5px;

    span > input {
        border-radius: 5px !important;
    }
}

.productEditPriceInputField > span > b {
    display: none;
}

.productEditCategories {

}

.productEditFormPriceField {
    display: inline-block;
    padding: 5px 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    height: 30px;
    margin-top: 2px;
    font-weight: 600;
}

.productEditFormStockQuantityField {
    display: inline-block;
    width: 50px;
    padding: 3px 5px;
    height: 33px;
    margin: 5px 0px;
}

.hiddenElement {
    display: none;
}

.productEditBtn {
    background-color: #EB008B;
    width: 180px;
    height: 39px;
    border-radius: 18px;
    padding-top: 2px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px;
    text-decoration: none;
}

.productEditBtn:hover, .productEditBtn:active, .productEditBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.productEditBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}

.productEditInputValidationMessage {
    color: #ff0000;
    font-size: 0.7em;

}

.productEditInputValidationMessageRtl {
    direction: rtl;
}