.errorAlertMain div {
    text-align: center;
    font-size: 1.1em;
}

.errorAlertMainWrapper {
    text-align: center;
}

.errorMessageWrapper {
    padding-top: 25px;
}

.errorAlertMainActionsWrapper {
    text-align: center;
}

.errorAlertMainActionsWrapperRTL {
    direction: rtl;
}

.errorAlertMainActionBtn {
    background-color: #EB008B;
    width: 180px;
    height: 39px;
    border-radius: 18px;
    padding-top: 2px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px auto;
    display: block;
    outline: none;
    border-width: 0px;
    text-decoration: none;
}

.errorAlertMainActionBtn:hover, .errorAlertMainActionBtn:active, .errorAlertMainActionBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.errorAlertMainActionBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}
