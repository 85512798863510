.alignRight {
    text-align: right;
}

.alignLeft {
    text-align: left;
}

.specialOfferCard {
    /*margin: 5px 5px auto;
    line-height: 1.6em;
    width: calc(50% - 10px);*/
    margin: 5px 0 auto;
    line-height: 1.6em;
    padding: 0px;

    @media (max-width: 769px) {
        margin: 5px 0 auto;
        line-height: 1.6em;
        /*width: unset;*/
    }
}

.specialOfferCardRTL {
    text-align: right;
    direction: rtl;
}

.cardHeader {
    padding: 0px;
    background-color: #ffffff;
    cursor: pointer;
    border-bottom: solid 0px #000000;
}

.cardRow {
    margin: 0px;
}

.cardColumn {
    padding: 0px;
}

.cardInfoContainer {
    flex-grow: 1;
    max-width: unset;
}

.cardTitle {
    /*display: flex;
    flex-direction: row;*/
    margin: 0px;
    text-align: left;
    padding: 5px;
}

.cardTitleRtl {
    text-align: right;
}

.cardTitleIcon {
    font-size: 1.0em;
    /*flex: 1 0 15%;*/
    text-align: right;
    padding-top: 4px;
}

.cardTitleIconRTL {
    text-align: left;
}

.cardRemoveIcon {
    font-size: 1.0em;
    /*flex: 1 0 15%;*/
    text-align: left;
    padding-top: 4px;
    cursor: default;
}

.cardRemoveIconRTL {
    text-align: right;
}

.cardEditSectionWrapper {
    padding: 0px 0px;
    line-height: 1.6em;
    font-weight: 600;
    font-size: 0.9em;
}

.cardEditRow {
    padding: 5px;
    margin: 0px;
    /*height: 50px;*/
}

.cardEditCol {
    padding: 0px;
}

.specialOfferRewardsRtl {
    text-align: right;
    direction: rtl;
}

.RewardEditTypes {
}

.specialOfferRewardsEditDropDown {
    width: 80px;
}

.specialOfferRewardsDropDown {
}

.specialOfferRewardsDropDown::after {
    margin-right: 10px;
}

.dropdownWindow {
    max-height: 200px;
    overflow-y: auto;
    max-width: 250px;
}

.productEditFormPriceField {
    display: inline-block;
    padding: 5px 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    height: 30px;
    margin-top: 2px;
    font-weight: 600;
}

.productEditNumeric {
    /*width: 88px;*/
}

.productEditPriceInputField {
    border-radius: 5px;

    span > input {
        border-radius: 5px !important;
    }
}

.productEditPriceInputField > span > b {
    display: none;
}

.productTrashBtn, .productTrashBtn:hover, .productTrashBtn:active, .productTrashBtn:visited {
    color: #000000;
}
