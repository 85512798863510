.dropZoneContainer {
    width: 100%;
    height: 125px;
}

.dropZone {
    width: 150px;
    height: 125px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    /*border: dashed 1px #000000;*/
    border: 1px dashed rgba(0, 0, 0, 0.2);
    margin: 0px auto;
    display: block;
}

.dropZoneBtn {
    margin: 0px auto;
    margin-top: 37px;
    color: #fff;
    font-size: 1em;
    right: auto;
    left: 0;
    height: 45px;
    width: 90%;
    padding: 10px 10px;
    border-radius: 32px;
    background-color: #ec02a1;
    border-color: #fd24b7;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropZoneBtn:hover, .dropZoneBtn:hover {
    color: #fff;
    background-color: #b9027e;
    border-color: #e2029a;
}

.dropZoneText {
    font-size: 1em;
    display: block;
    margin: 5px auto;
    text-align: center;
}

.thumbsContainer {
    /*width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;*/

    width: 150px;
    height: 125px;
    border-radius: 10px;
    object-fit: contain;
    object-position: center;
    /*border: dashed 1px #000000;*/
    border: 1px dashed rgba(0, 0, 0, 0.2);
    margin: 0px auto;
    display: block;
}
