

.container {
    margin-top: 20px;

    @media (max-width: 550px) {
        padding: 0px;
        margin: 10px auto;
        margin-top: 100px;
    }
}

.cardsWrapper {
    padding: 10px;

    @media (max-width: 550px) {
        padding: 0px;
        margin: 0px 10px 0px 10px;
    }
}


/*****************************************************/

.specialOffersAddCircle {
    background-color: #EB008B;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    cursor: pointer;
}

.specialOffersAddPlus {
    padding: 7px 10px;
}

.addPlusNavLink {
    padding: 0px;
}

/*****************************************************/
.specialOffersAlertMain div {
    text-align: center;
    font-size: 1.1em;
}

.specialOffersAlertBellWrapper {
    text-align: center;
}

.specialOffersAlertActionsWrapper {
    text-align: center;
}

.specialOffersAlertActionBtn {
    background-color: #EB008B;
    width: 180px;
    height: 39px;
    border-radius: 18px;
    padding-top: 2px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px auto;
    display: block;
    outline: none;
    border-width: 0px;
    text-decoration: none;
}

.specialOffersAlertActionBtn:hover, .specialOffersAlertActionBtn:active, .specialOffersAlertActionBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.specialOffersAlertActionBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}

.infiniteScrollQueue {
    margin: auto;
    text-align: center;
}