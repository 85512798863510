.deliveryZoneEdit {
}

.deliveryZoneEditRtl {
    text-align: right;
    direction: rtl;
}

.deliveryZoneEditContainer {
    /*margin: 10px 10px 50px 10px;*/
    margin: 0px;
    margin-top: -50px;
    /*padding-left: 0px !important;
    padding-right: 0px !important;*/
    padding: 0px;

    @media (max-width: 550px) {
        margin-top: 0px;
    }
}

.deliveryZoneEditTitleText {
    font-size: 1.4em;
    font-weight: 600;
    padding: 10px 5px;
    height: 50px;
}

.deliveryZoneEditTitleTextRtl {
    text-align: right;
    direction: rtl;
}

.topCloseButton {
    float: right;
}

.topCloseButtonRtl {
    float: left;
}

.deliveryZoneEditTitle {
    background-color: #352D57;
    height: 45px;
    color: #ffffff;
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1em;
    padding: 12px 5px;
    margin: 0px;
}

.deliveryZoneEditExplanationRow {
    min-height: 60px;
    padding-bottom: 10px;
    font-size: 1.3em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    border-bottom: 1px solid #000000;
}

.deliveryZoneEditRow {
    min-height: 60px;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    /* margin-bottom: 15px; */
    border-bottom: 1px solid #000000;
    padding-bottom: 10px;
}

.lastDeliveryZoneEditRow {
    min-height: 60px;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 10px 0px 15px 0px;
    padding-top: 6px;
    /* margin-bottom: 15px; */
    border-bottom: 0px solid #000000;
    text-align: center;
}

.deliveryZoneEditControlsCol {
    text-align: right;
    direction: ltr;
}

.deliveryZoneEditControlsColRtl {
    text-align: left;
    direction: ltr;
}

.deliveryZoneEditDropDown {
    width: 80px;
}

.deliveryZoneTagsDropDown {
    width: 100%;
    height: unset;
    min-height: 35px;
    overflow: visible;
    white-space: break-spaces;
    text-align: right;
}

.deliveryZoneEditNumeric {
    /*width: 88px;*/
}

.deliveryZoneEditInputField {
    border-radius: 5px;
}

.deliveryZoneEditInputField {
    direction: rtl;
    text-align: right;
}

.deliveryZoneEditPriceInputField {
    border-radius: 5px;

    span > input {
        border-radius: 5px !important;
    }
}

.deliveryZoneEditPriceInputField > span > b {
    display: none;
}

.deliveryZoneEditCategories {
}

.deliveryZoneEditFormPriceField {
    display: inline-block;
    padding: 5px 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    height: 30px;
    margin-top: 2px;
    font-weight: 600;
}

.deliveryZoneEditFormStockQuantityField {
    display: inline-block;
    width: 50px;
    padding: 3px 5px;
    height: 33px;
    margin: 5px 0px;
}

.hiddenElement {
    display: none;
}

.deliveryZoneEditBtn {
    background-color: #EB008B;
    width: 250px;
    height: 39px;
    border-radius: 18px;
    padding-top: 2px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px;
    text-decoration: none;
}

.deliveryZoneEditBtn:hover, .deliveryZoneEditBtn:active, .deliveryZoneEditBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.deliveryZoneEditBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}

.deliveryZoneEditInputValidationMessage {
    margin: 5px;
    color: #ff0000;

    @media (max-width: 550px) {
        font-size: 0.7em;
    }
}

.deliveryZoneEditInputValidationMessageRtl {
    direction: rtl;
}

.deliveryZoneEditFormPriceField {
    display: inline-block;
    padding: 5px 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    height: 30px;
    margin-top: 2px;
    font-weight: 600;
}

.deliveryZoneEditNumeric {
    /*width: 88px;*/
}

.deliveryZoneEditPriceInputField {
    border-radius: 5px;

    span > input {
        border-radius: 5px !important;
    }
}

.deliveryZoneEditPriceInputField > span > b {
    display: none;
}
