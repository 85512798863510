.order {
    margin-top: 110px;

    @media (max-width: 550px) {
        margin: 110px 0px 0px 0px;
    }
}

.orderRtl {
    direction: rtl;
    text-align: right;
}

.cardHeaderContainer {
    text-decoration: none;
    color: #ffffff;
    background-color: #352D57;
    padding: 0px;
    margin: 10px 0px;
    cursor: pointer;

    .headerBlock {
        display: block;
        width: 100%;
        height: 50px;
        color: #ffffff;
        padding: 10px;
        font-size: 1.4em;

        a:visited, a:active, a:focus {
            color: #ffffff;
        }
    }

    .closeHeaderBtn {
        display: none;
    }

    .headerBlock > .openHeaderBtn {
        font-weight: 800;
        font-size: 1em;
        float: right;
        color: #ffffff;
        margin-top: 10px;
    }

    .headerBlock.collapse > .closeHeaderBtn {
        font-weight: 800;
        font-size: 1em;
        float: right;
        color: #ffffff;
        margin-top: 10px;
        display: inline-block;
    }

    .headerBlock.collapse > .openHeaderBtn {
        display: none;
    }

    .headerBlockRtl > .openHeaderBtn {
        float: left;
    }

    .headerBlockRtl.collapse > .closeHeaderBtn {
        float: left;
    }
}

.orderRtl > .headerBlock > a:after {
    float: left;
}

.cardBodyContainer {
    padding: 0px;
}

.horrayMessage {
    width: 768px;
    min-height: 100px;
    padding: 20px;
    border-radius: 10px;
    color: #000000;
    background-color: #e3e1ee;
    font-size: 1.2em;
    text-align: center;
    margin: 130px auto;

    @media (max-width: 768px) {
        width: 80%;
        margin: 20px auto;
    }
}

.infiniteScrollQueue {
    margin: auto;
    text-align: center;
}