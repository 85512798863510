.shippingTime {
}

.shippingTimeRtl {
    text-align: right;
    direction: rtl;
}

.container {
    margin-top: 20px;

    @media (max-width: 550px) {
        padding: 0px;
        margin: 10px auto;
        /*margin-top: 100px;*/
    }
}

.cardsWrapper {
    padding: 10px;

    @media (max-width: 550px) {
        padding: 0px;
        margin: 0px 10px 0px 10px;
    }
}


/*****************************************************/

.shippingSettingsCol {
    font-weight: 600;
    font-size: 1.2em;
    padding-top: 2px;
}

.shippingTimesAddCircle {
    background-color: #EB008B;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    cursor: pointer;
}

.shippingTimesAddPlus {
    padding: 7px 10px;
}

.addPlusNavLink {
    padding: 0px;
}

/*****************************************************/
.shippingTimesAlertMain div {
    text-align: center;
    font-size: 1.1em;
}

.shippingTimesAlertBellWrapper {
    text-align: center;
}

.shippingTimesAlertActionsWrapper {
    text-align: center;
}

.shippingTimesAlertActionBtn {
    background-color: #EB008B;
    width: 180px;
    height: 39px;
    border-radius: 18px;
    padding-top: 2px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px auto;
    display: block;
    outline: none;
    border-width: 0px;
    text-decoration: none;
}

.shippingTimesAlertActionBtn:hover, .shippingTimesAlertActionBtn:active, .shippingTimesAlertActionBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.shippingTimesAlertActionBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}

.infiniteScrollQueue {
    margin: auto;
    text-align: center;
}

.shippingTimeEditBtn {
    background-color: #EB008B;
    width: 250px;
    height: 39px;
    border-radius: 18px;
    padding-top: 5px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px;
    text-decoration: none;
    outline: none;
}

.shippingTimeEditBtn:hover, .shippingTimeEditBtn:active, .shippingTimeEditBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.shippingTimeEditBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}