.cardsWrapper {
    padding: 10px;

    @media (max-width: 550px) {
        padding: 0px;
        margin: 110px 0px 0px 0px;
    }
}

/*****************************************************/

.modalProductsHeaderRTL {
    direction: rtl;
    text-align: right;
    /*flex-direction: row-reverse;*/
}

.modalProductsHeaderRTL h5 {
    /*float: left;*/
    width: 100%;
}

.modalProductsHeaderRTL button {
    float: left;
}

.modalProductBody {
    text-align: center;


    .dropbox {
        text-align: center;
        padding: 20px;
        width: 90%;
        margin: auto;
        margin-top: 50px;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        margin: 20px auto;
        padding: 20px;
    }

    .container img {
        height: 200px;
        width: 200px;
        margin-right: 15px;
    }

    .btn {
        padding: 15px;
        background-color: #de1a1a;
        color: white;
        font-weight: bold;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }

    .btn:hover {
        background-color: #945c5c;
    }
}

.modalProductContainer {
    min-height: 150px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

/*.modalProductImg {
    max-height: 300px;
    max-width: 100%;
    width: auto;
    margin: 0 auto;
}*/

/*****************************************************/

.modalAddProductBody {
    padding: 0px;
}

.productsAddCircle {
    background-color: #EB008B;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    cursor: pointer;
}

.productsAddPlus {
    padding: 7px 10px;
}

/*****************************************************/

.uploadImgContainer {
    /*overflow: hidden;
    transition: transform 0.3s ease-out;
    height: auto;
    transform: scaleY(1);
    transform-origin: top;*/


    display: block;
    /*overflow: hidden;
    width: 150px;
    height: 200px;*/
    margin-left: auto;
    margin-right: auto;
}

.uploadImgHidden {
    /*transform: scaleY(0);*/

    animation: introtop ease-out;
    animation-duration: 1.5s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}

@keyframes introtop {
    0% {
        width: 150px;
        height: 200px;
    }

    100% {
        width: 0;
        height: 0;
        display: none;
    }
}

.uploadImgBtn {
    background-color: #EB008B;
    width: 180px;
    height: 39px;
    border-radius: 18px;
    padding-top: 2px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px auto;
    text-decoration: none;
}

.uploadImgBtn:hover, .uploadImgBtn:active, .uploadImgBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.uploadImgBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}

.uploadImgBtnHide {
    display: none;
}

/*****************************************************/

.productsAlertMain div {
    text-align: center;
    font-size: 1.1em;
}

.productsAlertBellWrapper {
    text-align: center;
}

.productsAlertActionsWrapper {
    text-align: center;
}

.productsAlertActionBtn {
    background-color: #EB008B;
    width: 180px;
    height: 39px;
    border-radius: 18px;
    padding-top: 2px;
    color: #ffffff;
    font-size: 1.1em;
    margin: 10px auto;
    display: block;
    outline: none;
    border-width: 0px;
    text-decoration: none;
}

.productsAlertActionBtn:hover, .productsAlertActionBtn:active, .productsAlertActionBtn:visited {
    color: #ffffff;
    background-color: #EB008B;
}

.productsAlertActionBtn:disabled {
    opacity: 0.7;
    color: #cccccc;
}

.infiniteScrollQueue {
    margin: auto;
    text-align: center;
}